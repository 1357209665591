import { throttle } from "../../utilities/throttle.js";

const stickyNav = (function() {
  let $nav;
  let animating = false;
  let navPos;
  const init = function() {
    $nav = document.getElementById("main-nav");

    if ($nav !== undefined && $nav !== null) {
      navPos = $nav.getBoundingClientRect().y;
      let sticky = $nav.getAttribute("data-sticky");
      if (sticky == '"always"') {
        window.addEventListener("scroll", _alwaysSticky);
      } else {
        window.addEventListener("scroll", _handleSticky);
      }
    } else {
      console.warn("Sticky nav cant find nav");
    }
  };

  const _handleSticky = throttle(function(e) {

    if ( window.scrollY >= window.innerHeight * 0.5 && $nav.matches(".nav-change") === false && !animating ) {
      _setSticky();
  } else if ( window.scrollY <= window.innerHeight * 0.5 && $nav.matches(".nav-change") && !animating ) {
    _removeSticky();
  } 
}, 200 , { leading: true });
const _setSticky = () => {
  animating = true;

  $nav.classList.add("nav-change--hide", "nav-change");

  setTimeout(() => {
    $nav.classList.add("nav-change--show");
    $nav.classList.remove("nav-change--hide");
    animating = false;
  }, 200);
};
const _removeSticky = () => {
  animating = true;

  $nav.classList.add("nav-change--hide");

  setTimeout(() => {
    $nav.classList.remove( "nav-change--hide", "nav-change", "nav-change--show");
    animating = false;
  }, 200);
};
const _alwaysSticky = throttle((e) => {
  if ( window.scrollY >= navPos && $nav.matches(".nav-change") === false && !animating ) {
    $nav.classList.add("nav-change--show", "nav-change");
  } else if ( window.scrollY <= navPos && $nav.matches(".nav-change") && !animating ) {
    $nav.classList.remove("nav-change--show", "nav-change");
  }
}, 200, {leading: true});
return {
  init: init,
};
})();

export { stickyNav };