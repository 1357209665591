import {
  stopScroll,
  resumeScroll,
} from '../../utilities/scrollControl.js'

const navTrigger = (function(){

  let $trigger;
  let $nav;
  let $links;
  let $body;
  let $html;
  let state = 'closed';
  let animating = false;

  const init = () =>{
    _bindElements();
    if ( $nav !== undefined &&  $nav !== null){
      $trigger.addEventListener('click', toggleMenu );
      $links.forEach( $link => {
        $link.addEventListener('click', toggleMenu );
      });
   }
  } 
  const _bindElements = () =>{
    $nav = document.getElementById('main-nav');
    if ( $nav !== undefined &&  $nav !== null){
    $links = Array.from(document.querySelector('.main-nav__bar').querySelectorAll('a'));
    $body = document.body;
    $html= document.getElementById('web');
    $html.style.overflowY = "scroll";
    $trigger = document.getElementById('main-nav-trigger');
    } else {
    
      console.warn("Cant find Nav")

    }
  }
  const toggleMenu = () =>{
    if ( state == 'closed'){
      _handleOpen();
    } else{
      _handleClose();
    }   
  };
  const _handleOpen = ()=>{
    _handleAnimation();
    setTimeout( ()=>{
      $nav.classList.add('is-open');
      $trigger.setAttribute('aria-expanded', 'true');
      $trigger.classList.add('is-active');
      stopScroll();
      state = 'open';
    }, 50);
  }
  const _handleClose = () =>{
    _handleAnimation();
    $nav.classList.remove('is-open');
    $trigger.setAttribute('aria-expanded', 'false');
    $trigger.classList.remove('is-active');
    resumeScroll();
    state = 'closed';
  }
  const _handleAnimation = () =>{
    $nav.classList.add('is-animating');
    setTimeout( ()=>{
      $nav.classList.remove('is-animating');
    }, 500);
  }
  return {
    init: init
  }
})();

export { navTrigger };