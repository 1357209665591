import Swup from 'swup';
import SwupScrollPlugin from '@swup/scroll-plugin';
import 'lazysizes'; 
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import GLightbox from 'glightbox';


import { 
	scrollTo,
	stopScroll,
	resumeScroll,
} from './utilities/scrollControl';  


import { formShow } from './modules/formShow'; 
import { stickyNav } from '@m/nav/sticky-nav';  
import { navTrigger } from './modules/nav/navTrigger';  
import { labelSlide } from '@m/label-slide';
import { glideInit } from './modules/glideInit.js'

// VUE  

// App main  
const main = async () => { 
	labelSlide.init();  
	navTrigger.init(); 
	glideInit.init();
  formShow.init();  
  stickyNav.init();

//-------
//  click on a link to scroll to id
//


const lightbox = GLightbox({
  touchNavigation: false,
  loop: false,
  autoplayVideos: true,
  closeOnOutsideClick: true,
  width: 'auto',
  openEffect: 'fade',
  closeEffect: 'fade',
  slideEffect: 'fade',
  cssEfects: {
    fade: { in: 'gFadeIn', out: 'gFadeOut' },
  },
});



const scrollToHash = document.querySelectorAll('.js-scroll-to-hash')
scrollToHash.forEach((item) => {
  item.addEventListener('click', (e) => {
    e.stopPropagation()
    e.preventDefault()

    const href =
    item.getAttribute('href') != null
    ? item.getAttribute('href')
    : item.getAttribute('data-target')

    let target = document.querySelector(href);
    item.classList.add('active');
    item.parentNode.children.forEach(sib => {
      if (sib !== item){
        sib.classList.remove('active');
      }
    });
    scrollTo(target, 500) 
  })
})
}; 



const swup = new Swup({  
  // linkSelector: '.swup-link',
  animationSelector: '[class*="swup-transition-"]',
  linkSelector: 'a[href^="' + window.location.origin + '"]:not([data-no-swup]):not([target="_blank"]):not([download]):not([class*="glightbox"]),a[href^="/"]:not([data-no-swup]), a[href^="http://example.com"]:not([data-no-swup])',

  plugins: [
  new SwupScrollPlugin({
  	doScrollingRightAway: false,
  	animateScroll: false,
  }),
  ],
});



main();


swup.on('contentReplaced', () => {
	main();
});
