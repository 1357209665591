
// convert this to a class based function

import Glide from '@glidejs/glide';

// https://glidejs.com/docs/setup/



const glideInit = (function(){

	let glideInstance = {};

	const mobileCheck = () =>{
		if ( window.innerWidth <= 934 ){
			return true;
		} else{
			return false;
		}
	}   
	
	const initGlider = function(el, glideOptions, options = { mobOnly :false } ){
		/// check to see if an element exists 
		let $el = Array.from( document.querySelectorAll(el) );
		// if it does
		if( $el.length > 0 ){
			//
			// create a promise to instantiate 
			const instantiate = new Promise( (resolve, reject) =>{

				glideInstance[el] = new Glide(el, glideOptions );

				if( glideInstance[el]._o.type.length  > 0 ){
					resolve(true)
				}
			}) 
			// 
			instantiate.then( ( ) => {
				// console.log(glideInstance);
				console.log(el);
				if( options.mobOnly !== true ||  ( mobileCheck() == true && options.mobOnly == true)) {
					// console.log('mount');

					glideInstance[el].mount();
				} 
				if (mobileCheck() == false && options.mobOnly == true){

					glideInstance[el].disable();
					_handleMobOnly(glideInstance[el]);

				}
				
			}).then( ()=>{
				if(options) {
					if(options.persistActive){
						glideInstance[el].on( 'move' , () => {
							let currentActive = document.querySelector('.glide__slide--active');
							currentActive.classList.add('still-active');
						});
					}
				}
			});
		}
		

	};

	const _handleMobOnly = function(instance){
		window.addEventListener('resize',() =>{
			if ( mobileCheck() && instance.disabled == true ){
				instance.enable();
				instance.mount();

				instance.on('resize', ()=> {
					if ( mobileCheck() == false &&  instance.disabled == false ){
						// console.log('destroy');
						instance.disable();
						instance.destroy();
					}
				})
			} 
		});
	};
	
	const init = function(){
		
		initGlider('.glide--images', {
			type:'carousel',
			animationTimingFunc: 'cubic-bezier(0, 0, 0.58, 1.0)',
			animationDuration: 600,
			perView: 1,
			gap:0,
			autoplay: 5000,
			
		}); 

		initGlider('.glide--video', {
			type:'carousel',
			animationTimingFunc: 'cubic-bezier(0, 0, 0.58, 1.0)',
			animationDuration: 700,
			perView: 1,
			touchRatio: 0,
			gap:0,

		}); 

		
		initGlider('.glide--images-3', {
			type:'carousel',
			animationTimingFunc: 'cubic-bezier(0, 0, 0.58, 1.0)',
			animationDuration: 600,
			perView: 1,
			gap:0,
			autoplay: 5000, 

		});

		initGlider('.glide--content', {
			type:'carousel',
			animationTimingFunc: 'cubic-bezier(0, 0, 0.58, 1.0)',
			animationDuration: 700,
			perView: 4,
			gap:15,
			breakpoints:{
				1440:{
					perView: 4,
				},
				992:{
					perView: 2,
				},
				600:{
					perView: 1.3,
				},
			},

		});



	}

	return {
		initGlider : initGlider,
		init : init,
		
	}


})()

export { glideInit };