

const formShow = (function () {



	const init = () => { 

			const openTrigger = document.getElementById("formOpen");
			const closeTrigger = document.getElementById("formClose");
			const formContainer = document.getElementById("form-overlay");
			const carouselContainer = document.getElementById("ideas-carousel");

			if(openTrigger != null ){

				openTrigger.addEventListener("click",function(e){
				    formContainer.classList.add("open");
				    carouselContainer.classList.add("open");

				});
			}

			if(closeTrigger != null ){

				closeTrigger.addEventListener("click",function(e){
			    	formContainer.classList.remove("open"); 
			   		carouselContainer.classList.remove("open");
				}); 
				
			}

	};


	return{
		init: init
	}

})();

export { formShow };
